import React from 'react';
import Layout from '../components/layout';
import * as styles from './index.module.css';
import { graphql, Link } from 'gatsby';

const IndexPage = ({ data }) => {
  const posts = data.allMarkdownRemark ? data.allMarkdownRemark.nodes : [];

  return (
    <Layout>
      <section className={`${styles.jumbo} jumbo h-52 sm:h-72`}>
        <div className="container">
          <h1 className="text-5xl sm:text-8xl text-red-800">Marcian CX</h1>
          <p>Philosophy, art, and kinetics in pursuit of the masculine mandate.</p>
        </div>
      </section>

      <section className="content">
        <div className="container">
          <div className="posts">
            {posts.map((post, index) => {
              const titleWithSpaces = post.frontmatter.title
                .replace(/_/g, ' ')
                .toUpperCase();

              const linkName = post.frontmatter.title.replace("'", '');

              // Check if fileAbsolutePath is defined before using it
              const isPoem = post.fileAbsolutePath && post.fileAbsolutePath.includes('/poetry/');
              const isEssay = post.fileAbsolutePath && post.fileAbsolutePath.includes('/essays/');
              const linkPath = isPoem ? `/poetry/${linkName}` : isEssay ? `/essays/${linkName}` : '/';

              return (
                <React.Fragment key={post.id}>
                  <article>
                    <Link to={linkPath}>
                      <h2 className="tracking-[10px]" style={{ whiteSpace: 'pre-wrap' }}>{titleWithSpaces}</h2>
                    </Link>
                    <p className="text-xs mt-0">{post.frontmatter.date}</p>
                    <div className="relative max-h-24 overflow-hidden">
                      <div
                        className="absolute bottom-0 left-0 w-full h-8 bg-gradient-to-b from-transparent to-white"
                        aria-hidden="true"
                      ></div>
                      <div
                        className="text-base text-gray-800"
                        dangerouslySetInnerHTML={{ __html: post.html }}
                      />
                    </div>
                  </article>
                  {index !== posts.length - 1 && <hr />}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        id
        frontmatter {
          title
          date(formatString: "MMMM DD, YYYY")
        }
        fields {
          slug
        }
        fileAbsolutePath
        html
      }
    }
  }
`;

export default IndexPage;
